import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { makeStyles, Zoom } from '@material-ui/core'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'

import Placeholders from './Livestream/PlaceholderStates'
import LivestreamIntermission from './LivestreamIntermission'
import { ConvertTimeStamp } from '../utils/helper'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    margin: 0
  },
  textCountdown: {
    marginBottom: 0,
    marginTop: theme.spacing(1)
  }
}))

const TextBox = ({ title, description }) => {
  const classes = useStyles()

  return (
    <Placeholders>
      <h4 className={classes.title}>{title}</h4>
      <h1 className={classes.textCountdown}>{description}</h1>
    </Placeholders>
  )
}

const CountdownEnded = ({ session }) => {
  const [isShowIntermission, setIsShowIntermission] = useState(false)
  const { t } = useTranslation('event')

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShowIntermission(() => true)
    }, 10 * 1000)

    return () => clearTimeout(timer)
  }, [session.startAt])

  return (
    <React.Fragment>
      {isShowIntermission ? (
        <LivestreamIntermission />
      ) : (
        <TextBox title={t('welcome')} description={session.fullTitle} />
      )}
    </React.Fragment>
  )
}

const LivestreamNotStarted = ({ session, timeCurrent, duration }) => {
  const { t } = useTranslation('event')
  const formatTimeNumber = (number) => {
    return ('0' + number).slice(-2)
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <CountdownEnded session={session} />
    } else {
      // Render a countdown
      return (
        <TextBox
          title={`${t('steam_will_start', { adj: days > 0 ? 'on' : 'in' })}`}
          description={
            days > 0
              ? `${moment(
                  ConvertTimeStamp(session.startAt).seconds * 1000
                ).format('DD MMMM YYYY, HH:mm')}`
              : `${formatTimeNumber(hours)} : ${formatTimeNumber(
                  minutes
                )} : ${formatTimeNumber(seconds)}`
          }
        />
      )
    }
  }

  return (
    <Zoom in={true}>
      <Countdown date={timeCurrent + duration} renderer={renderer} />
    </Zoom>
  )
}

export default LivestreamNotStarted
