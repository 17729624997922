import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('locale') || 'en',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    debug: process.env.NODE_ENV !== 'production',
    fallbackLng: 'en'
  })

export default i18n
