import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { useStyles } from '../../utils/styled'

const customStyles = makeStyles((theme) => ({
  root: {
    height: '320px',
    textAlign: 'center',
    padding: '0 8px'
  },
  description: {
    ...theme.poll.description
  }
}))

const EmptyState = () => {
  const helperClasses = useStyles()
  const customClasses = customStyles()
  const { t } = useTranslation('event')

  return (
    <Box
      className={customClasses.root}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant="h5"
        className={`${customClasses.description} ${helperClasses.mb1}`}
      >
        {t('no_message')}
      </Typography>
      <Typography
        variant="caption"
        className={`${customClasses.description} ${helperClasses.px1}`}
      >
        {t('no_message_description')}
      </Typography>
    </Box>
  )
}

export default EmptyState
