import React from 'react'
import { makeStyles, Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Placeholders from './Livestream/PlaceholderStates'

const useStyles = makeStyles(() => ({
  intermissionTitle: {
    fontSize: '40px'
  }
}))

const LivestreamIntermission = () => {
  const classes = useStyles()
  const { t } = useTranslation('event')
  return (
    <Placeholders>
      <Box>
        <Typography className={classes.intermissionTitle}>
          <strong>{t('intermission')}</strong>
        </Typography>
        <Typography>{t('steam_will_back')}</Typography>
      </Box>
    </Placeholders>
  )
}

export default LivestreamIntermission
