import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Box,
  Typography,
  makeStyles,
  Tooltip,
  Zoom
} from '@material-ui/core'
import { ref, getDownloadURL } from 'firebase/storage'
import { useTranslation } from 'react-i18next'

import { storageRef } from '../../../Services/firebaseApp'
import { useStyles } from '../../../utils/styled'

import Skeleton from './Skeleton'

const firebaseStorage = storageRef

const customStyles = makeStyles((theme) => ({
  image: {
    width: '65px',
    height: '65px',
    borderRadius: '8px'
  },
  imageBg: theme.poll.imageBg,
  weightBold: {
    fontWeight: 'bold'
  },
  labelRoot: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: 1.2
  },
  mySelectedLabel: theme.poll.progressbar.mySelectedLabel,
  myTopSelectLabel: theme.poll.progressbar.myTopSelectLabel
}))

const Title = ({ mySelected, topSelected }) => {
  const customClasses = customStyles()
  const { t } = useTranslation('poll')

  let label = t('your_answer')
  let color = 'mySelectedLabel'

  if (topSelected && !mySelected) {
    label = t('top_answer')
    color = 'myTopSelectLabel'
  }

  return (
    <Typography variant="caption" className={customClasses[color]}>
      {label}
    </Typography>
  )
}

const PollOption = ({
  className,
  imageRef,
  label,
  votedPercent,
  votedCount,
  showVotedPercent = false,
  showVotedCount = false,
  mySelected = false,
  topSelected = false
}) => {
  const classes = useStyles()
  const customClasses = customStyles()

  const [image, setImage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let mounted = true
    if (mounted) {
      const getImageUrl = async (image) => {
        const imageRef = ref(firebaseStorage, image)
        const imageUrl = await getDownloadURL(imageRef)
        setImage(imageUrl)
      }

      if (imageRef) {
        setIsLoading(true)

        setTimeout(() => {
          getImageUrl(imageRef)
          setIsLoading(false)
        }, 1000)
      }
    }
    return () => (mounted = false)
  }, [imageRef])

  const Image = ({ image }) => {
    return (
      <Avatar
        src={image}
        variant="rounded"
        className={`${customClasses.image} ${customClasses.imageBg} ${classes.ml1} ${classes.mr2}`}
      >
        <FontAwesomeIcon icon={['far', 'image']} />
      </Avatar>
    )
  }

  const Option = () => {
    return (
      <Box width="100%" height="100%" display="flex" alignItems="center">
        {image && <Image image={image} />}

        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          {(mySelected || topSelected) && (
            <Title mySelected={mySelected} topSelected={topSelected} />
          )}

          <Box display="flex" justifyContent="space-between">
            <Tooltip
              title={label}
              placement="top-start"
              TransitionComponent={Zoom}
            >
              <Typography
                variant="h6"
                className={`${customClasses.labelRoot} ${
                  mySelected ? customClasses.weightBold : null
                }`}
              >
                {label}
              </Typography>
            </Tooltip>
            <Box>
              {showVotedCount && (
                <Typography
                  variant="caption"
                  className={mySelected ? customClasses.weightBold : null}
                >
                  {votedCount}
                </Typography>
              )}

              {showVotedPercent && (
                <Typography
                  variant="caption"
                  className={mySelected ? customClasses.weightBold : null}
                >
                  {showVotedCount ? ` (${votedPercent}%) ` : `${votedPercent}%`}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  const OptionInnerComponent = ({ imageRef, image }) => {
    if (isLoading) {
      return <Skeleton image={imageRef} />
    } else {
      if (imageRef) {
        if (image) return <Option />
        return <Skeleton image={true} />
      }

      return <Option />
    }
  }

  return (
    <Box className={className}>
      <OptionInnerComponent imageRef={imageRef} image={image} />
    </Box>
  )
}

export default PollOption
