import React, { useContext } from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import AppContext from '../../Services/AppContext'
import { ProgressLine } from './Components'

const customStyles = makeStyles((theme) => ({
  placeholder: theme.poll.placeholder
}))

const progressBarColor = (idx, pollStatus, isEndedMode, mySelected) => {
  if (pollStatus === 'active') {
    if (isEndedMode && idx === 0) return 'topSelect'
    return 'common'
  }

  const progressColor = () => {
    if (idx === 0 && mySelected) {
      return 'topSelectWithMySelected'
    } else if (idx === 0) {
      return 'topSelect'
    } else if (mySelected) {
      return 'mySelected'
    }

    return 'common'
  }

  return progressColor()
}

const PollVotedResult = ({
  poll = null,
  choices = null,
  isEndedMode = false
}) => {
  const customClasses = customStyles()
  const { t } = useTranslation('poll')
  const appContext = useContext(AppContext.Context)
  const profile = appContext.currentProfile

  const progressHeight = poll.choiceWithImage ? '81px' : '45px'

  const choiceByPosition = choices.sort((a, b) => a.position - b.position)
  const choiceByVotedCount = choiceByPosition.sort(
    (a, b) => b.votedCount - a.votedCount
  )

  const calVotedPercent = (votedCount) =>
    votedCount > 0 ? ((votedCount / poll?.votedCount) * 100).toFixed(2) : 0

  {
    /* render top answer */
  }
  // const renderTopSelected = () => {
  //   const percentage = calVotedPercent(choiceByVotedCount[0].votedCount)
  //   const isTopSelected = percentage > 0 ? true : false

  //   return (
  //     <ProgressLine
  //       choice={choiceByVotedCount[0]}
  //       topSelected={isTopSelected}
  //       percentage={percentage}
  //       progressHeight={progressHeight}
  //       progressStyles={progressBarColor(0, poll?.status, isEndedMode, false)}
  //     />
  //   )
  // }

  const isUserVotedPoll = choices.find((c) => c.voter.includes(profile.id))

  return (
    <React.Fragment>
      <Box mb={1}>
        {choiceByPosition.map((choice, idx) => {
          const mySelected = choice.voter.includes(profile.id)
          const percentage = calVotedPercent(choice.votedCount)
          const topSelected = idx === 0 && percentage > 0 ? true : false

          return (
            <ProgressLine
              key={idx}
              choice={choice}
              mySelected={mySelected}
              topSelected={topSelected}
              percentage={percentage}
              progressHeight={progressHeight}
              progressStyles={progressBarColor(
                idx,
                null,
                isEndedMode,
                mySelected
              )}
            />
          )
        })}
      </Box>

      {!isUserVotedPoll && (
        <Box textAlign="center">
          <Typography variant="caption" className={customClasses.placeholder}>
            {t('did_not_answer')}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  )
}

export default PollVotedResult
