import React, { useContext, useState } from 'react'
import { Box, Divider, Typography, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import AppContext from '../../Services/AppContext'
import PollContext from '../../Services/PollContext'
import { useStyles, pollCardStyles } from '../../utils/styled'

import { PollHead, QuestionAndDescription } from './Components'
import PollVotedResult from './PollVotedResult'
import { FormVotePoll } from './Components'
import PollResultModal from '../Modal/PollResultModal'

const customStyles = makeStyles((theme) => ({
  viewResultButton: {
    ...theme.poll.viewResultButton
  }
}))

const PollEndedInnerComponent = ({ eventId, sessionId }) => {
  const classes = pollCardStyles()
  const customClasses = customStyles()
  const appContext = useContext(AppContext.Context)
  const pollContext = useContext(PollContext.Context)
  const profile = appContext.currentProfile
  const { poll, choices } = pollContext
  const { t } = useTranslation('poll')

  const [isOpenDialogResult, setisOpenDialogResult] = useState(false)

  const handdleOpenDialogPollResult = () => {
    setisOpenDialogResult(true)
  }

  const handdleCloseDialogPollResult = () => {
    setisOpenDialogResult(false)
  }

  if (!poll) return null
  if (choices.length === 0) return null

  const isHideResults = poll.type === 'neverDisplay'

  return (
    <Box mb={2} className={`${classes.root} ${classes.cursorPointer}`}>
      <QuestionAndDescription poll={poll} />

      {isHideResults ? (
        <FormVotePoll
          profileId={profile.id}
          eventId={eventId}
          sessionId={sessionId}
          isEndedMode={true}
        />
      ) : (
        <PollVotedResult poll={poll} choices={choices} isEndedMode />
      )}
      <Box mt="24px">
        <Divider />
      </Box>
      {(appContext.ticketRole === 'host' ||
        appContext.ticketRole === 'invisibleHost') && (
        <Box
          className={customClasses.viewResultButton}
          mt="24px"
          textAlign="center"
          onClick={() => handdleOpenDialogPollResult()}
        >
          <Typography>{t('view_result')}</Typography>
        </Box>
      )}

      <PollResultModal
        poll={poll}
        isOpenDialogResult={isOpenDialogResult}
        handdleCloseDialogPollResult={handdleCloseDialogPollResult}
      />
    </Box>
  )
}

const EndedPolls = (props) => {
  const { sessionId, polls } = props
  const classes = useStyles()
  const { t } = useTranslation('poll')

  if (polls.length === 0) return null

  return (
    <React.Fragment>
      <PollHead title={t('previous_poll')} />

      <div className={classes.spacerXs} />

      {polls.map((poll) => (
        <PollContext.Provider key={poll.id} sessionId={sessionId} poll={poll}>
          <PollEndedInnerComponent {...props} />
        </PollContext.Provider>
      ))}
    </React.Fragment>
  )
}

export default EndedPolls
