import React from 'react'
import { makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CustomButton } from '../../utils/styled'
import { ThemeSessionType } from '../../utils/type'

const useStyles = makeStyles((theme: ThemeSessionType) => ({
  hamburgerbutton: {
    ...theme.button.hamburger,
    padding: `${theme.spacing(1)}px !important`,
    minWidth: theme.spacing(5)
  }
}))

type HamburgerButtonProps = {
  onClick: () => void
}

export default function HamburgerButton({ onClick }: HamburgerButtonProps) {
  const classes = useStyles()
  return (
    <CustomButton
      data-testid="hamburger-button"
      variant="outlined"
      classes={{
        root: classes.hamburgerbutton
      }}
      onClick={() => onClick()}
    >
      <FontAwesomeIcon icon={['far', 'bars']} size={'lg'} />
    </CustomButton>
  )
}
