import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

import { variables, ButtonLinkPOPLIVE } from '../../utils/styled'
import PathHelper from '../../utils/PathHelper'
import FanMeeting from '../../assets/images/pictogram/Fan-meeting.svg'
import LiveStreaming from '../../assets/images/pictogram/Live-streaming.svg'
import VirtualConference from '../../assets/images/pictogram/Virtual-conference.svg'
import Webinar from '../../assets/images/pictogram/Webinar.svg'

const customStyles = makeStyles((theme) => ({
  becomeToHostSectionWrapper: {
    background: variables.grayBase,
    padding: '100px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      padding: '64px 16px'
    }
  },
  welcomeText: {
    fontSize: '56px',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '36px'
    }
  },
  welcomeSecondeText: {
    marginTop: '8px',
    marginBottom: '32px',
    color: variables.grayDark
  },
  featurePictrogram: {
    display: 'grid',
    color: 'white',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    rowGap: '36px',
    columnGap: '36px',
    [theme.breakpoints.down('md')]: {
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr 1fr'
    }
  },
  pictogramWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    gap: '6px'
  },
  pictogram: {
    backgroundColor: 'white',
    borderRadius: '50%',
    width: '100px',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

function BecomeToHostSection() {
  const customClasses = customStyles()
  const { t } = useTranslation()
  return (
    <Box className={customClasses.becomeToHostSectionWrapper}>
      <Box mb="24px" textAlign="center" color="white">
        <Typography variant="h1">
          <b>{t('footer.title')}</b>
        </Typography>
        <Typography variant="h4" className={customClasses.welcomeSecondeText}>
          {t('footer.sub_title')}
        </Typography>
      </Box>
      <Box className={customClasses.featurePictrogram}>
        <Box className={customClasses.pictogramWrapper}>
          <Box className={customClasses.pictogram}>
            <img src={Webinar} alt="Webinar" />
          </Box>
          <Typography variant="h5">Webinar</Typography>
        </Box>
        <Box className={customClasses.pictogramWrapper}>
          <Box className={customClasses.pictogram}>
            <img src={VirtualConference} alt="VirtualConference" />
          </Box>
          <Typography variant="h5">
            Virtual <br /> Conference
          </Typography>
        </Box>
        <Box className={customClasses.pictogramWrapper}>
          <Box className={customClasses.pictogram}>
            <img src={FanMeeting} alt="FanMeeting" />
          </Box>
          <Typography variant="h5">Fan Meeting</Typography>
        </Box>

        <Box className={customClasses.pictogramWrapper}>
          <Box className={customClasses.pictogram}>
            <img src={LiveStreaming} alt="LiveStreaming" />
          </Box>
          <Typography variant="h5">
            Live <br /> Streaming
          </Typography>
        </Box>
      </Box>
      <Box mt="32px">
        <ButtonLinkPOPLIVE variant="contained">
          <a
            href={PathHelper.app.popliveSale()}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              display="flex"
              gridGap="8px"
              alignItems="center"
              style={{ textTransform: 'capitalize' }}
            >
              {t('become_host')}
              <FontAwesomeIcon icon={['far', 'arrow-right']} />
            </Box>
          </a>
        </ButtonLinkPOPLIVE>
      </Box>
    </Box>
  )
}

export default BecomeToHostSection
