import React, { useContext, useEffect, useState } from 'react'
import { navigate } from '@reach/router'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import AppContext from '../../Services/AppContext'
import EventContext from '../../Services/EventContext'
import PathHelper from '../../utils/PathHelper'

import { LobbyBar, SessionList, HintsContainer } from './LobbyPage/index'

const customeStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    paddingLeft: 64,
    paddingRight: 64,
    paddingBottom: 24,

    [theme.breakpoints.down('md')]: {
      paddingLeft: 24,
      paddingRight: 24
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  hithRoot: {
    ...theme.lobby.hint.root,
    width: 360,

    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}))

const LobbyPage = () => {
  const customClasses = customeStyles()
  const appContext = useContext(AppContext.Context)
  const eventContext = useContext(EventContext.Context)
  const { firstTimeRenderLobby, flags } = appContext
  const { event, actions, sessions } = eventContext
  const [shouldRender, setShouldRender] = useState(false)
  const { t } = useTranslation('event')
  useEffect(() => {
    let mounted = true
    if (mounted && allowSessions.length === 1 && firstTimeRenderLobby) {
      const session = allowSessions[0]
      appContext.actions.setFirstTimeRenderLobby(false)
      navigate(
        PathHelper.event.sessionPath(
          event.slug,
          session.sessionType,
          session.sessionId
        )
      )
    } else {
      setShouldRender(true)
    }
    return () => (mounted = false)
  }, [])

  const handleClick = (eventSlug, sessType, sessId) =>
    navigate(
      `${PathHelper.event.sessionPath(eventSlug, sessType, sessId)}${
        flags ? `?flags=${flags}` : ''
      }`
    )

  const allowSessions = sessions
    .map((session) => {
      if (actions.checkSessionPermission(session.sessionId)) {
        return session
      }
      return null
    })
    .filter((session) => session)

  return (
    <>
      {shouldRender && (
        <>
          <LobbyBar event={event} />
          <Box display="flex">
            <Box className={customClasses.content}>
              <Box mb="16px">
                <Typography variant="h4">{t('choose_room')}</Typography>
              </Box>
              {sessions.map((session) => {
                const isPermission = actions.checkSessionPermission(
                  session.sessionId
                )

                if (isPermission)
                  return (
                    <SessionList
                      key={session.sessionId}
                      eventSlug={event.slug}
                      sessionId={session.sessionId}
                      session={session}
                      onClick={() =>
                        handleClick(
                          event.slug,
                          session.sessionType,
                          session.sessionId
                        )
                      }
                    />
                  )
                return null
              })}
            </Box>
            <Box className={customClasses.hithRoot} px={2} pt={3}>
              <HintsContainer />
            </Box>
          </Box>
        </>
      )}
    </>
  )
}

export default LobbyPage
