import React, { useContext } from 'react'
import { navigate, useLocation } from '@reach/router'
import {
  Avatar as MuiImg,
  Box,
  List,
  ListItem,
  makeStyles,
  Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

import NavButton from './NavSidebar/NavButton'
import PathHelper from '../../utils/PathHelper'
import EventContext from '../../Services/EventContext'
import ThemeContext from '../../Theme/ThemeContext'
import AppContext from '../../Services/AppContext'
import { useStyles, navSideBar, CustomButton } from '../../utils/styled'
import { Logos } from '../../utils/images'
import { SessionType, ThemeSessionType } from '../../utils/type'

const customStyles = makeStyles((theme: ThemeSessionType) => ({
  button: {
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: 4,
    backgroundColor: 'transparent'
  },
  listSessionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 330px)',
    height: '100%'
  }
}))

const ButtonToLobbyPage = ({ link }: { link: string }) => {
  const location = useLocation()
  const classes = useStyles()
  const customClasses = customStyles()
  const { t } = useTranslation('event')

  const handleClick = () => {
    if (location.pathname !== link) {
      navigate(link)
    }
  }

  return (
    <CustomButton
      className={customClasses.button}
      variant="outlined"
      color="primary"
      onClick={handleClick}
    >
      <Box display="flex" alignItems="center">
        <FontAwesomeIcon icon={['far', 'home']} className={classes.mr1} />
        <Typography variant="h6">
          <strong>{t('lobby')}</strong>
        </Typography>
      </Box>
    </CustomButton>
  )
}

interface AppContextStateType {
  flags?: string
}

const NavSidebar = () => {
  const classes = useStyles()
  const navbarClasses = navSideBar()
  const eventContext = useContext(EventContext.Context)
  const themeContext = useContext(ThemeContext.Context)
  const appContext = useContext(
    AppContext.Context as React.Context<AppContextStateType>
  )
  const { flags } = appContext
  const { event, sessions } = eventContext
  const isHidePopliveLogo = event?.isHidePopliveLogo || false
  const customClasses = customStyles()

  const getLogoByTheme = () => {
    const eventTheme = themeContext.eventTheme
    if (eventTheme?.layoutTheme === 'dark') return Logos.popliveHorizontalWhite
    return Logos.popliveHorizontal
  }

  return (
    <List>
      {!isHidePopliveLogo && (
        <ListItem>
          <div>
            <img
              src={getLogoByTheme()}
              alt="POP LIVE"
              className={classes.imgResponsive}
            />
          </div>
        </ListItem>
      )}

      <ListItem>
        <a href={event.posterURL} target="_blank" rel="noopener noreferrer">
          <MuiImg
            variant="rounded"
            src={event.posterURL}
            alt={event.title}
            className={navbarClasses.posterEvent}
          >
            Poster
          </MuiImg>
        </a>
      </ListItem>
      <ListItem>
        <ButtonToLobbyPage
          link={`${PathHelper.event.lobbyPath(event.slug)}${
            flags ? `?flags=${flags}` : ''
          }`}
        />
      </ListItem>

      <ListItem className={customClasses.listSessionWrapper}>
        {sessions.map((session: SessionType) => {
          if (eventContext.actions.checkSessionPermission(session.sessionId)) {
            return (
              <NavButton
                key={session.sessionId}
                sessionId={session.sessionId}
                icon={session.icon}
                text={session.title}
                link={`${PathHelper.event.sessionPath(
                  event.slug,
                  session.sessionType,
                  session.sessionId
                )}${flags ? `?flags=${flags}` : ''}`}
                isLive={session.isLive}
                viewersIncremental={session?.viewersIncremental ?? 0}
                startAt={session.startAt}
                endAt={session.endAt}
              />
            )
          }
          return null
        })}
      </ListItem>
    </List>
  )
}

export default NavSidebar
