import React, { useContext } from 'react'
import { Box, Typography, makeStyles, Avatar } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

import DialogLayout from './DialogLayout'
import { DialogContent, useStyles, variables } from '../../utils/styled'
import ThemeContext from '../../Theme/ThemeContext'
import { SecuredUrl } from '../../utils/helper'

const customeStyles = makeStyles((theme) => ({
  textCustom: {
    fontSize: '16px'
  },
  previewImageWrapper: {
    position: 'relative',
    aspectRatio: '16/9',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    cursor: ({ link }) => {
      if (link !== '' && link) {
        return 'pointer'
      }
    },
    borderRadius: '8px',
    border: `1px solid ${variables.grayLight}`
  },
  iconLink: {
    position: 'absolute',
    right: 8,
    bottom: 16,
    height: '24px',
    width: '24px',
    color: 'white',
    padding: 4,
    borderRadius: '100px',
    backgroundColor: 'rgba(0,0,0,0.25)',
    '& svg': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  }
}))

const DefaultWMRC = () => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="h5">
        <strong>
          You can view the ticket reference code in the following ways:
        </strong>
      </Typography>
      <Box className={classes.my2}>
        <Typography variant="h5">
          <strong>Option 1 </strong>
          <br />
          In your email inbox that you use to register for the event.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5">
          <strong>Option 2 </strong>
          <br />
          In your Eventpop account that you use to register for the event.
        </Typography>

        <Typography variant="h5">
          1. Go to <strong>eventpop.me</strong>
        </Typography>
        <Typography variant="h5">
          2. <strong>Login/Register</strong> to eventpop (Use the same email
          that you used to register for the event)
        </Typography>
        <Typography variant="h5">
          3. Click on <strong>My Wallet</strong>{' '}
        </Typography>
        <Typography variant="h5">
          4. Click on <strong>Tickets</strong>
        </Typography>
      </Box>
    </>
  )
}

const CustomTextWMRC = ({ whereIsMyRefCodeCustomText }) => {
  const customClasses = customeStyles()
  return (
    <div
      dangerouslySetInnerHTML={{ __html: whereIsMyRefCodeCustomText }}
      className={customClasses.textCustom}
    />
  )
}

const LinkWMRC = ({ whereIsMyRefCodeLink }) => {
  return (
    <Box>
      <a
        href={SecuredUrl(whereIsMyRefCodeLink.link)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Typography>
          {whereIsMyRefCodeLink.display !== '' && whereIsMyRefCodeLink.display
            ? whereIsMyRefCodeLink.display
            : whereIsMyRefCodeLink.link}
        </Typography>
      </a>
    </Box>
  )
}

const ImageWMRC = ({ WMRFimagePreview, link }) => {
  const customClasses = customeStyles({ link })
  return (
    <Box className={customClasses.previewImageWrapper}>
      {link !== '' || link ? (
        <a href={SecuredUrl(link)} target="_blank" rel="noopener noreferrer">
          <Avatar
            src={WMRFimagePreview}
            id="uploadImage"
            variant="rounded"
            classes={{ root: customClasses.previewImageWrapper }}
          />
          <Box className={customClasses.iconLink}>
            <FontAwesomeIcon icon={['far', 'external-link']} size="xs" />
          </Box>
        </a>
      ) : (
        <Avatar
          src={WMRFimagePreview}
          id="uploadImage"
          variant="rounded"
          classes={{ root: customClasses.previewImageWrapper }}
        />
      )}
    </Box>
  )
}

export default function WhereIsMyRefCodeModal({
  isOpenDialog,
  handdleCloseDialog
}) {
  const themeContext = useContext(ThemeContext.Context)
  const { eventSetting } = themeContext
  const { t } = useTranslation()
  const render = () => {
    if (!eventSetting?.whereIsMyRefCodeSetting) {
      return <DefaultWMRC />
    } else {
      const WMRCsetting = eventSetting.whereIsMyRefCodeSetting
      if (WMRCsetting.whereIsMyRefCodeMode === 'link') {
        return (
          <LinkWMRC whereIsMyRefCodeLink={WMRCsetting.whereIsMyRefCodeLink} />
        )
      } else if (WMRCsetting.whereIsMyRefCodeMode === 'customText') {
        return (
          <CustomTextWMRC
            whereIsMyRefCodeCustomText={WMRCsetting.whereIsMyRefCodeCustomText}
          />
        )
      } else if (WMRCsetting.whereIsMyRefCodeMode === 'image') {
        return (
          <ImageWMRC
            WMRFimagePreview={WMRCsetting.whereIsMyRefCodeImage.imageUrl}
            link={WMRCsetting.whereIsMyRefCodeImage.link}
          />
        )
      } else return <DefaultWMRC />
    }
  }

  return (
    <DialogLayout
      open={isOpenDialog}
      handleClose={handdleCloseDialog}
      title={t('event_page.where_is_my_ref_code.title')}
      showCloseIcon={true}
    >
      <DialogContent>{render()}</DialogContent>
    </DialogLayout>
  )
}
