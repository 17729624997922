import {
  Button,
  makeStyles,
  withStyles,
  TextField,
  Checkbox,
  Radio,
  Dialog,
  Tooltip,
  createStyles,
  DialogContent as MuiDialogContent
} from '@material-ui/core'

export const variables = {
  gray: '#B2B2B4',
  grayDark: '#7F8082',
  grayBase: '#000106',
  grayLight: '#e5e5e6',
  grayLighther: '#F5F5F6',
  eventpopPrimary: '#260176',
  eventpopPrimaryMedium: '#513491',
  brandInfo: '#5673B8',
  warning: '#F9C606',
  brandInfoLight: '#EEF1F7',
  brandSuccess: '#2E953E',
  brandWarningLight: '#FEF9E6',
  brandSuccessLight: '#EAF4EB',
  brandPrimaryLight: '#E9E5F1',
  brandDanger: '#EE3124',
  popliveRed: '#FF1234',
  popliveRedShade02: '#EC0023',
  primaryOrange: '#F68A33',
  facebook: '#3360FF',
  gradientPoster: '225deg, #513491 0%, #FF1234 100%'
}

export const borderRadius = {
  base: '4px',
  icon: '8px',
  modal: '16px',
  navMenu: '32px'
}

export const useStyles = makeStyles((theme) =>
  createStyles({
    mt0: {
      marginTop: 0
    },
    mt1: {
      marginTop: theme.spacing(1)
    },
    mt2: {
      marginTop: theme.spacing(2)
    },
    mt3: {
      marginTop: theme.spacing(3)
    },
    mb0: {
      marginBottom: 0
    },
    mb1: {
      marginBottom: theme.spacing(1)
    },
    mb3: {
      marginBottom: theme.spacing(3)
    },
    mb5: {
      marginBottom: theme.spacing(5)
    },
    mr1: {
      marginRight: theme.spacing(1)
    },
    mr2: {
      marginRight: theme.spacing(2)
    },
    mx1: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    mx2: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    },
    mx3: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3)
    },
    mx5: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5)
    },
    my1: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    my2: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    py0: {
      paddingTop: 0,
      paddingBottom: 0
    },
    py2: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    py4: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    },
    px1: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    px2: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    px4: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    },
    spacerXs: {
      height: theme.spacing(2)
    },
    spacer: {
      height: theme.spacing(3)
    },
    spacerMd: {
      height: theme.spacing(4)
    },
    maxHeight: {
      height: '100%'
    },
    textCenter: {
      textAlign: 'center'
    },
    textRight: {
      textAlign: 'right'
    },
    textBrandDanger: {
      color: variables.brandDanger
    },
    textBrandInfo: {
      color: variables.brandInfo
    },
    textBrandSuccess: {
      color: variables.brandSuccess
    },
    textGray: {
      color: variables.gray
    },
    textGrayBase: {
      color: variables.grayBase
    },
    textGrayDark: {
      color: variables.grayDark
    },
    textPopliveRed: {
      color: variables.popliveRed
    },
    fontWeightNormal: {
      fontWeight: 'normal !important'
    },
    textPrimaryMedium: {
      color: `${variables.eventpopPrimaryMedium} !important`
    },
    textWhite: {
      color: '#fff'
    },
    textShadow: {
      textShadow: '0px 4px 64px rgba(0, 0, 0, 0.2)'
    },
    textEllipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical'
    },

    textEllipsis2Line: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical'
    },

    borderLineBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      color: variables.gray,

      '& .border-line': {
        width: '100%',
        borderTop: `1px solid ${variables.grayLight}`
      }
    },
    textLink: {
      color: variables.eventpopPrimary,
      textDecoration: 'none'
    },
    textPrimaryMediumLink: {
      color: variables.eventpopPrimaryMedium,
      textDecoration: 'none',

      '&:hover': {
        color: variables.eventpopPrimary
      }
    },
    imgResponsive: {
      maxWidth: '100%'
    },
    imgShowTitle: {
      maxWidth: '100%',
      objectFit: 'cover',
      [theme.breakpoints.down('sm')]: {
        width: '78px',
        height: '110px',
        borderRadius: '12px'
      }
    },
    alignItemsCenter: {
      alignItems: 'center'
    },
    justifyContentCenter: {
      justifyContent: 'center'
    },
    cursorPointer: {
      cursor: 'pointer'
    },
    customSelect: {
      width: '100%',
      '& label': {
        background: '#fff'
      },
      '& select': {
        paddingTop: '10.5px',
        paddingBottom: '10.5px'
      }
    },
    backgroundEventOnBlur: {
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      position: 'absolute',
      top: '-30px',
      left: '-30px',
      right: '-30px',
      bottom: '-30px',
      filter: 'blur(15px)',
      zIndex: '-1'
    },
    avatarWrapper: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center'
    }
  })
)

export const layoutPoster = makeStyles((theme) => ({
  posterBox: {
    position: 'relative',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      paddingTop: '100%'
    },

    '&.ratio-sixteen-nine': {
      paddingTop: '56.25%'
    }
  },

  placeholderPosterEvent: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: '-1'
  },

  posterEventBox: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: '1',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    '& img': {
      maxWidth: '80%',
      maxHeight: '420px',

      '&.has-shadow': {
        boxShadow: '0px 4px 64px rgba(0, 0, 0, 0.2)'
      }
    }
  },

  titleBoxWithGradient: {
    position: 'absolute',
    zIndex: '1',
    opacity: '0.5',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: 'background 0.3s, border-radius 0.3s, opacity 0.3s',
    background: variables.grayBase
  },

  titleBox: {
    position: 'absolute',
    zIndex: '2',
    bottom: '8px',
    left: '24px',
    right: '24px',
    color: '#fff',
    textAlign: 'center',
    padding: '0 40px'
  }
}))

export const livestreamStyles = makeStyles((theme) => ({
  videoDescriptionBox: {
    overflow: 'auto'
  },

  videoBox: {
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    background: '#fff',
    zIndex: 9,

    [theme.breakpoints.up('sm')]: {
      position: 'relative'
    },

    '@media (orientation: landscape)': {
      position: 'relative',
      zIndex: 99
    }
  }
}))

export const navSideBar = makeStyles((theme) => ({
  posterEvent: {
    borderRadius: '8px !important',
    width: '128px !important',
    height: '181px !important',
    backgroundColor: theme.palette.primary.main
  }
}))

export const pollCardStyles = makeStyles((theme) => ({
  root: {
    ...theme.poll.root,
    padding: 16,
    borderRadius: 8
  },
  cursorPointer: {
    cursor: 'pointer'
  }
}))

export const modalStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2)
  },
  paper: {
    ...theme.modal.paper,
    boxShadow: theme.shadows[5],
    position: 'relative',
    maxWidth: '85%'
  },
  xs: {
    width: 320,
    minWidth: 300,

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  sm: {
    width: 440,
    minWidth: 300,

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}))

export const CustomButton = withStyles((theme) => ({
  root: {
    ...theme.button.root,
    fontWeight: 'normal'
  },
  containedPrimary: {
    ...theme.button.containedPrimary,
    fontWeight: 'bold'
  },
  outlinedPrimary: {
    ...theme.button.outlinedPrimary,
    fontWeight: 'bold'
  }
}))(Button)

export const ButtonLinkPOPLIVE = withStyles({
  root: {
    backgroundColor: variables.popliveRed,
    fontWeight: 'bold',
    color: '#fff',
    '& a': {
      textDecoration: 'none',
      color: 'white'
    },
    '&:hover': {
      backgroundColor: variables.popliveRedShade02
    }
  }
})(Button)

export const CustomInput = withStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        ...theme.customInput.borderColor
      }
    }
  }
}))(TextField)

export const FacebookButton = withStyles({
  root: {
    borderColor: variables.facebook,
    color: variables.facebook
  }
})(CustomButton)

export const TextButton = withStyles({
  root: {
    fontWeight: 'normal',
    color: variables.eventpopPrimaryMedium,

    '&:hover': {
      backgroundColor: variables.grayLighther
    }
  }
})(Button)

export const PollCheckbox = withStyles((theme) => ({
  root: theme.poll.option.root,
  checked: {}
}))(Checkbox)

export const PollRadio = withStyles((theme) => ({
  root: theme.poll.option.root,
  checked: {}
}))(Radio)

export const DialogLayout = withStyles((theme) => ({
  root: {},
  paper: {
    boxShadow: 'unset',
    borderRadius: borderRadius.modal
  }
}))(Dialog)

export const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(1)
  }
}))(MuiDialogContent)

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.16)',
    margin: '12px',
    color: variables.grayBase,
    minWidth: '250px'
  }
}))(Tooltip)
