import React, { useEffect, useRef, useContext } from 'react'
import {
  Chip as MuiChip,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import numeral from 'numeral'
import { useTranslation } from 'react-i18next'

import SessionContext from '../../../Services/SessionContext'
import { getViewersJoinedSession } from '../../../Services/SessionService'
import { SessionViewersType, ThemeSessionType } from '../../../utils/type'

interface ViewersCountProps {
  count: number
  viewersIncremental?: number
  liveStreamState: string
  eventSlug: string
  sessionId: string
}

const customeStyles = makeStyles((theme: ThemeSessionType) => ({
  chipRoot: {
    ...theme.chip.viewerCount,
    height: '1.8em',
    fontSize: 12,
    borderRadius: '4px',
    '& .MuiChip-label': {
      paddingRight: '6px'
    }
  },
  viewerNumber: {
    fontFamily: 'Arial'
  }
}))

export default function ViewersCount({
  viewersIncremental = 0,
  liveStreamState,
  eventSlug,
  sessionId
}: ViewersCountProps) {
  const sessionContext = useContext(SessionContext.Context)
  const { eventViewers } = sessionContext
  const firstTimeRender = useRef(true)
  const countViewersJoined = useRef(0)
  const theme = useTheme()
  const xsSizeDown = useMediaQuery(theme.breakpoints.down('xs'))
  const customClass = customeStyles()
  const { t } = useTranslation('event')
  const findSessionViewers = eventViewers.find(
    (sessionViewer: SessionViewersType) => sessionViewer.sessionId === sessionId
  )

  useEffect(() => {
    const getTotalUserJoinedSession = async () => {
      const result = (await getViewersJoinedSession(eventSlug, sessionId)) as {
        users: { total: number }
      }

      countViewersJoined.current = result.users.total ?? 0
      firstTimeRender.current = false
    }

    if (liveStreamState === 'ended' && firstTimeRender.current) {
      getTotalUserJoinedSession()
    }
  }, [liveStreamState])

  let text = ''

  const sumViewers = (findSessionViewers?.viewers ?? 0) + viewersIncremental
  let viewers = (findSessionViewers?.viewers ?? 0) + viewersIncremental

  if (sumViewers > 999) {
    viewers = numeral(Math.floor(sumViewers)).format('0.0a')
  }

  if (liveStreamState === 'ended') {
    text = xsSizeDown ? t('people_joined') : t('people_joined_session')
    viewers = (countViewersJoined.current ?? 0) + viewersIncremental
    if (viewers > 999) {
      viewers = numeral(Math.floor(viewers)).format('0.0a')
    }
  }

  return (
    <MuiChip
      icon={<FontAwesomeIcon icon={['far', 'users']} />}
      label={
        <div>
          <span className={customClass.viewerNumber}>{viewers}</span>
          {` ${text}`}
        </div>
      }
      size="medium"
      className={`${customClass.chipRoot}`}
    />
  )
}
