import {
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
  createStyles
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import DialogLayout from './DialogLayout'
import { DialogContent, CustomButton } from '../../utils/styled'

type Props = {
  isOpenTimeOutModal: boolean
  handleCloseDialogTimeout: () => void
  extendLink?: string
}

const customStyles = makeStyles((theme) =>
  createStyles({
    bottonWrapper: {
      marginTop: 32,
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 18
    }
  })
)

export default function TimeoutModal({
  isOpenTimeOutModal,
  handleCloseDialogTimeout,
  extendLink
}: Props) {
  const { t } = useTranslation('event')
  const customClasses = customStyles()
  const theme = useTheme()
  const smSizeDown = useMediaQuery(theme.breakpoints.down('sm'))
  const handleOpenExtendLink = () => {
    if (extendLink) {
      if (/^(http:\/\/|https:\/\/)/.test(extendLink)) {
        // URL already contains http:// or https://
        window.open(extendLink, '_blank')
      } else {
        // URL does not contain http:// or https://
        const fullUrl = 'https://' + extendLink
        window.open(fullUrl, '_blank')
      }
    }
  }

  return (
    <DialogLayout
      open={isOpenTimeOutModal}
      handleClose={handleCloseDialogTimeout}
      title="Session timed out"
      showCloseIcon={false}
      icon="exclamation-triangle"
      error={undefined}
      variant={undefined}
    >
      <DialogContent>
        <Typography variant="h5">
          {extendLink ? t('time_limit_extend') : t('time_limit')}
        </Typography>

        <div
          className={customClasses.bottonWrapper}
          style={{
            flexDirection: smSizeDown ? 'column' : 'row'
          }}
        >
          <div
            style={{
              order: smSizeDown ? 2 : 1
            }}
          >
            <CustomButton
              variant={
                extendLink || extendLink === '' ? 'outlined' : 'contained'
              }
              size="large"
              color="primary"
              fullWidth={smSizeDown ? true : false}
              onClick={handleCloseDialogTimeout}
            >
              Okay
            </CustomButton>
          </div>

          {extendLink && (
            <div
              style={{
                order: smSizeDown ? 1 : 2
              }}
            >
              <CustomButton
                variant="contained"
                size="large"
                color="primary"
                fullWidth={smSizeDown ? true : false}
                onClick={handleOpenExtendLink}
              >
                Extend Session
              </CustomButton>
            </div>
          )}
        </div>
      </DialogContent>
    </DialogLayout>
  )
}
